<template>
    <div class="home_marriage_test_payment" >
        <header>
            <div class="top_information_box">
                <i class="head_portrait_icon"><img src="http://cdn.zhiyileiju.cn/WechatIMG258.jpeg" alt=""></i>
                <div class="right_text_buttom_box">
                    <p class="text text_top">老师1对1为你解疑答惑</p>
                    <p class="text text_center">内容看不懂？想了解更多？加我微信吧<button class="buttom buttom_anchor"  @click="backToTheTopCallback">私聊老师</button></p>
                    <p class="text text_bottom">微信号：duoduo070902<button class="buttom buttom_copy tag-read" data-clipboard-text="duoduo070902" @click="copy">复制</button></p>
                </div>
            </div>
        </header>
        <section>
            <div class="top_box__">
                <div class="card_box">
                    <div class="card_top_box">
                        <h1>您的感情烦恼</h1>
                    </div>
                    <img src="https://cdn.zhiyileiju.cn/1461634006654_.pic_hd.jpg" alt="" class="img_icom_left">
                    <img src="https://cdn.zhiyileiju.cn/1451634006653_.pic_hd.jpg" alt="" class="img_icom_right">
                    <div class="content_box_">
                        <div class="content_box_top_" >
                            <img src="https://cdn.zhiyileiju.cn/WechatIMG263.jpeg" alt="">
                            <i class="qr_code" v-if="this.$route.query.qr !== undefined"><img src="https://cdn.zhiyileiju.cn/WechatIMG625.jpeg" alt=""></i>
                            <i class="qr_code" v-else><img src="https://cdn.zhiyileiju.cn/WechatIMG415.jpeg" alt=""></i>
                            <!-- <i class="picture_1"><img src="http://cdn.zhiyileiju.cn/WechatIMG2560.jpeg" alt=""></i>
                            <div class="text_box">
                                <p class="text_p text_p_bottom_border">我为什么找不到真爱？</p>
                                <p class="text_p">感情发展不明朗，好烦！</p>
                                <p class="text_p">我要与ta相伴一生一世！</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="top_box__">
                <div class="card_box">
                   <p class="title_header_"><i class="title_header_left_icon"><img src="https://cdn.zhiyileiju.cn/WechatIMG264.pic" alt=""></i> 卦下占卜 答案你有 <i class="title_header_right_icon"><img src="https://cdn.zhiyileiju.cn/WechatIMG265.pic" alt=""></i></p>
                   <div class="placard_img_box">
                       <img src="https://cdn.zhiyileiju.cn/WechatIMG255.jpeg" alt="">
                       <div class="buttom_img_box" @click="backToTheTopCallback">
                           <img src="https://cdn.zhiyileiju.cn/WechatIMG254.jpeg" alt="">
                       </div>
                   </div>
                   <p class="text_content">
                       情感解决专家均来自道家全真教，有丰富的婚
                        姻困扰解决经验，集六爻、八字命理，堪舆风
                        水、梅花易数、奇门遁甲予一身，擅长姻缘预
                        测，八字合婚，运势详解，婚姻挽回，忧虑难
                        事，助财旺运。
                   </p>
                </div>
            </div>
            <div class="top_box__" id="agentTitle">
                <div class="card_box">
                    <div class="header_portrait_text_box">
                        <i class="head_portrait_icon"><img src="http://cdn.zhiyileiju.cn/WechatIMG258.jpeg" alt=""></i>
                        <span class="text_wx">老师微信号:duoduo070902</span>
                        <button class="buttom_copy tag-read" data-clipboard-text="duoduo070902" @click="copy">复制</button>
                    </div>
                    <div class="qr_code_box">
                        <img v-if="this.$route.query.qr !== undefined" src="https://cdn.zhiyileiju.cn/WechatIMG626.jpeg" alt="">
                        <img v-else src="https://cdn.zhiyileiju.cn/WechatIMG414.png" alt="">
                    </div>
                    <p class="qr_code_text">请长按识别二维码，添加老师微信</p>
                    <p class="phone_code_box" @click="callPhone('15273606172')">或直拨电话咨询：15273606172</p>
                    <p class="title_headline">- 服务流程 -</p>
                    <div class="img_1"><img src="https://cdn.zhiyileiju.cn/WechatIMG253.jpeg" alt=""></div>
                    <p class="title_headline">- 添加指引 -</p>
                    <div class="img_2" v-if="this.$route.query.qr !== undefined" ><img src="https://cdn.zhiyileiju.cn/WechatIMG624.jpeg" alt=""></div>
                    <div class="img_2" v-else><img src="https://cdn.zhiyileiju.cn/WechatIMG416.jpeg" alt=""></div>
                </div>
            </div>
            <div class="top_box__">
                <div class="card_box">
                   <div class="evaluate_box">
                       <img src="https://cdn.zhiyileiju.cn/WechatIMG259.jpeg" alt="">
                   </div>
                </div>
            </div>
        </section>
        <footer>
           
        </footer>
        
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import { $statWeChatCli } from "../../api/stat"
export default {
    data () {
        return {
            // qr:this.$route.query.qr
        }
    },
    computed: {
       
    },
     async beforeCreate() {
    
  },
    created () {
        console.log('this.$route.query.qr',this.$route.query.qr)
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted () {
        //  $stat("v1_marry_result");
        this.dataRequest()
    },
    methods:{
        dataRequest () {
            // console.log('shuju',this.$route.query.type)
            if( this.$route.query.type !== undefined ) {
                let statWeChatCli_data = {
                    type:this.$route.query.type,
                    menu_name:this.$route.query.menu_name
                }
                $statWeChatCli(statWeChatCli_data).then( ( res ) => {
                
                })  
            }
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				// this.fixedbtn = true;
			} else {
				// this.fixedbtn = false;
			}
		},
        copy() {//复制按钮
            var clipboard = new Clipboard('.tag-read')
            clipboard.on('success', e => {
                this.$toast.success("复制成功");
                // console.log('复制成功')
                //  释放内存
                // clipboard.destory()
            })
                clipboard.on('error', e =>{
                // 不支持复制
                console.log('该浏览器不支持复制')
                // 释放内存
                // clipboard.destory()
            })
        },
        backToTheTopCallback () {//锚点
            // $stat("v1_marry_pay_bottom");
            document.querySelector("#agentTitle").scrollIntoView({behavior: "smooth", block: "start" });
        },
        callPhone (phoneNumber) {//调用呼叫功能
            window.location.href = 'tel://' + phoneNumber
        }
      
    }
}
</script>

<style lang="scss" scoped>
.home_marriage_test_payment{
    background: #A90017;
    height: 100%;
    overflow: auto;
    header{
        .top_information_box{
            display: flex;
            height: 1.86rem;
            background: #FFF0D3;
            .head_portrait_icon{
                display: inline-block;
                width: 1.12rem;
                margin-top:.38rem ;
                margin-right: .22rem;
            }
            .right_text_buttom_box{
                margin-top: .25rem;
                width: 7rem;
                .text{
                    font-size: .28rem;
                    .buttom{
                        font-size: .24rem;
                        color: #FFFFFF;
                        background: #C91C18;
                        border-radius: .1rem;
                    }
                    .buttom_anchor{
                        width: 1.21rem;
                        height: .54rem;
                        line-height: .54rem;
                        margin-left: .12rem;
                    }
                    .buttom_copy{
                        width: 1.02rem;
                        height: .36rem;
                        line-height: .36rem;
                        margin-left: .24rem;
                    }
                }
                .text_top{
                    font-size: .32rem;
                    color:#815919;
                }
                .text_center{
                    color: #525252;
                }
                .text_bottom{
                    color: #A90017;
                }
            }
        }
    }
    section{
        margin-top: .7rem;
        .top_box__:first-child{
            .content_box_{
                .content_box_top_{
                    // display: flex;
                    position: relative;
                    margin-top: .3rem;
                    padding-bottom: .3rem;
                    .qr_code{
                        display: inline-block;
                        position: absolute;
                        left: .4rem;
                        top: 4rem;
                        width: 2.06rem;
                    }
                    .picture_1{
                        display: inline-block;
                        width: 2.10rem;
                        margin-left: .1rem;
                    } 
                    .text_box{
                        margin-left: .48rem;
                        .text_p{
                            font-size: .32rem;
                            color: #525252;
                        }
                        .text_p_bottom_border{
                            border-bottom: .5px dotted #e3725e;
                        }
                    }
                }
            }
        }
        .top_box__{
            width: 100%;
            margin-top: .2rem;
            .card_box{
                position: relative;
                width: 7.08rem;
                background: #FFF0D3;;
                border:.1rem solid #DC5744;
                border-radius: .3rem;
                margin: 0 auto;
                padding-bottom: .5rem;
                .title_header_{
                    position: relative;
                    text-align: center;
                    font-size: .36rem;
                    color: #A90017;
                    font-weight: 400;
                    margin-top: .4rem;
                    i{
                        display: inline-block;
                        width: 1.2rem;
                        position: absolute;
                    }
                    .title_header_left_icon{
                        left: .75rem;
                    }
                    .title_header_left_icon{
                        right: .75rem;
                    }
                }
                .placard_img_box{
                    position: absolute;
                    width: 7.5rem;
                    top: 1.3rem;
                    left: -.22rem;
                    .buttom_img_box{
                        position: absolute;
                        width: 3.55rem;
                        top: 1.8rem;
                        left: .2rem;
                    }
                }
                .text_content{
                    font-size: .32rem;
                    color: #525252;
                    padding-top: 3.6rem;
                    padding-left: .2rem;
                    padding-right: .2rem;
                    text-align: center;
                }
                .header_portrait_text_box{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: .4rem;
                    .head_portrait_icon{
                        display: inline-block;
                        width: .7rem;
                    }
                    .text_wx{
                        font-size: .32rem;
                        color: #525252;
                        margin-left: .16rem;
                    }
                    .buttom_copy{
                        width: 1.02rem;
                        height: .36rem;
                        border-radius: .1rem;
                        background: #C91C18;
                        color: #fff;
                        font-size: .24rem;
                        line-height: .36rem;
                        margin-left: .16rem;
                    }
                }
                .qr_code_box{
                    width: 2.79rem;
                    margin: auto;
                    margin-top:.18rem;
                }
                .qr_code_text{
                    font-size: .36rem;
                    color: #A90017;
                    text-align: center;
                }
                .phone_code_box{
                    font-size: .3rem;
                    color: #525252;
                    text-align: center;
                    margin-top: .24rem;
                }
                .title_headline{
                    font-size: .36rem;
                    color: #A90017;
                    text-align: center;
                    margin-top: .5rem;
                    margin-bottom: .47rem;
                }
                .img_1{
                    width: 5.8rem;
                    margin: auto;
                }
                .img_2{
                    width: 6.01rem;
                    margin: auto;
                }
                .evaluate_box{
                    margin-top: .44rem;
                }
                .card_top_box{
                    position: relative;
                    top: -0.54rem;
                    width: 5.54rem;
                    height: 1.08rem;
                    margin: 0 auto;
                    background: url("http://cdn.zhiyileiju.cn/title_bg.jpg ");
                    background-size: 100% 100%;
                    h1{
                        font-size: .36rem;
                        text-align: center;
                        line-height: 1.08rem;
                        color: #FFF0D3;
                    }
                }
                .img_icom_left{
                    width: 1.45rem;
                    position: absolute;
                    top: -0.08rem;
                    left: -0.03rem;
                }
                .img_icom_right{
                    width: 1.45rem;
                    position: absolute;
                    top: -0.08rem;
                    right: -0.05rem;
                }
                p{
                    font-size: .28rem;
                    color: #A90017;
                    // text-align: center;
                    span{
                        margin-left: .28rem;
                    }
                    // border-bottom: 1px solid #FCAF93;
                }
            }  
        }
    }
    footer{
    }
}
</style>